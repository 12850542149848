import React from 'react';
import {
  PageHero,
  GoodBad,
  GoodBadItem,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Event Details" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Event Details" />
      <Section>
        <Paragraph>
          The "musts" include two teams and <Link href="/words/content-elements/date-time">a date</Link>. They're the
          backbone of organizing video in Hudl. Everything else depends on where (and when) the event is mentioned.
        </Paragraph>
      </Section>

      <Section title="Versus">
        <Paragraph>It’s a single preposition with several possible variations—but we’re sticking to one.</Paragraph>
        <Paragraph>
          Specific to games and scrimmages, <em>versus</em> separates the two teams competing against each other and
          implies a location in the process.
        </Paragraph>
        <SectionSubhead>Hudl's Preference</SectionSubhead>
        <Paragraph>
          We should always display versus as{' '}
          <strong>
            lowercase <em>vs</em> with no punctuation
          </strong>
          , similar to other abbreviations like months, positions and am/pm.
        </Paragraph>
        <Paragraph>
          Never spell it out, never use a single <em>v</em>, and never capitalize. (That includes all caps.)
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>East High School VS Middle Central Catholic</Paragraph>
            <Paragraph>MNHS varsity volleyball v. MNHS JV</Paragraph>
            <Paragraph>Tuesday, Jan 3 at 3pm versus AAHS</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>East High School vs Middle Central Catholic</Paragraph>
            <Paragraph>MNHS varsity volleyball vs MNHS JV</Paragraph>
            <Paragraph>Tuesday, Jan 3 at 3pm vs AAHS</Paragraph>
          </GoodBadItem>
        </GoodBad>

        <SectionSubhead>Schedules</SectionSubhead>
        <Paragraph>
          The most common appearance of versus is a team’s schedule, but it won’t apply to every event.
        </Paragraph>
        <Paragraph>
          <strong>Use versus for home games and scrimmages.</strong> Always list the featured team (whose profile you’re
          viewing) first. If the featured team is <em>away</em>, use @ instead.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Inglewood High School @ South Academy (home)</Paragraph>
            <Paragraph>Inglewood High School vs South Academy (away)</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>South Academy vs Inglewood High School (home)</Paragraph>
            <Paragraph>South Academy @ Inglewood High School (away)</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <SectionSubhead>Highlights</SectionSubhead>
        <Paragraph>
          Another instance of versus appears alongside highlights. In this case, <strong>always use vs</strong>, never
          the @ symbol.
        </Paragraph>
        <Paragraph>
          Highlights are less about the where and when, and more about the who. Versus gets the job done every time, but
          keep the same order as a schedule with your featured team listed first.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Inglewood High School vs South Academy</Paragraph>
            <Paragraph>South Academy @ Inglewood High School</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>South Academy vs Inglewood High School</Paragraph>
          </GoodBadItem>
        </GoodBad>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
